<script>
export default {
  name: 'AuthCredentials',
  data: function () {
    return {
      error: null,
      response: null,
    };
  },
  mounted() {
    this.getCredentials();
  },
  methods: {
    getCredentials: function () {
      this.$auth
        .currentUserCredentials()
        .then((result) => (this.response = result))
        .catch((err) => (this.error = err.message));
    },
  },
};
</script>

<template>
  <div v-if="error" class="card">
    <div class="card-header bg-danger header-elements-inline">
      <h6 class="card-title">Temporary AWS Credentials</h6>

      <div class="header-elements">
        <a @click="getCredentials">Refresh Credentials</a>
      </div>
    </div>

    <div class="card-body">
      <p class="text-danger">{{ error }}</p>
    </div>
  </div>

  <div v-else class="card">
    <div class="card-header bg-light header-elements-inline">
      <h6 class="card-title">Temporary AWS Credentials</h6>

      <div class="header-elements">
        <button class="btn btn-primary btn-sm" @click="getCredentials">Refresh Credentials</button>
      </div>
    </div>

    <div v-if="response" class="card-body">
      <div class="form-group row">
        <label class="col-form-label col-lg-2" for="accessKeyId">AccessKeyId</label>
        <div class="col-lg-10">
          <input id="accessKeyId" class="form-control" v-model="response.accessKeyId" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-lg-2" for="secretAccessKey">SecretAccessKey</label>
        <div class="col-lg-10">
          <input id="secretAccessKey" class="form-control" v-model="response.secretAccessKey" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-lg-2" for="sessionToken">SessionToken</label>
        <div class="col-lg-10">
          <textarea
            id="sessionToken"
            rows="12"
            cols="3"
            class="form-control"
            v-model="response.sessionToken"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
